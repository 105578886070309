import React from "react";
import { BsFacebook, BsGlobe2, BsInstagram, BsLinkedin } from "react-icons/bs";

function Socials() {
  return (
    <div className="flex  gap-4 justify-center md:justify-start md:px-7 py-4 ">
      <a href="https://www.kempetro.com.br/" target="_blank" rel="noreferrer">
        <BsGlobe2 color="white" className="w-6 h-6" />
      </a>
      <a
        href="https://www.facebook.com/kempetroengenharia/?locale=pt_BR"
        target="_blank"
        rel="noreferrer"
      >
        <BsFacebook color="white" className="w-6 h-6" />
      </a>
      <a
        href="https://www.instagram.com/kempetroengenharia/"
        target="_blank"
        rel="noreferrer"
      >
        <BsInstagram color="white" className="w-6 h-6" />
      </a>
      <a
        href="https://www.linkedin.com/company/kempetro-engenharia/mycompany/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin color="white" className="w-6 h-6" />
      </a>
    </div>
  );
}

export default Socials;
