import React from "react";
import logo from "../assets/images/logo.svg";
const Header = () => {
  return (
    <header className="px-[30px] pt-[42px]">
      <nav className="flex gap-[24px]">
        <img alt="Logo Kempetro" src={logo} />

        <h2 className="text-white font-bold text-[12px] md:text-[19px]  ">
          INOVAÇÃO E <br />
          TECNOLOGIA <br />
          NA ENGENHARIA
        </h2>
      </nav>
    </header>
  );
};

export default Header;
