import logoBackground from "./assets/images/logo_background.svg";

import Card from "./components/Card";

import { useState } from "react";
import tour from "./assets/images/tour.jpg";
import galeria from "./assets/images/galeria.jpeg";

import styles from "./styles";
import Header from "./components/Header";
import Socials from "./components/Socials";

function App() {
  const [active, setActive] = useState("1");
  return (
    <div className="bg-[#F18700]  overflow-hidden ">
      <Header />
      <Socials />

      <main className=" relative h-full">
        <img className="absolute " alt="Imagem de fundo" src={logoBackground} />
        <div className={`${styles.paddings} `}>
          <div className="flex flex-col justify-start">
            <div className=" flex lg:flex-row flex-col min-h-[80vh] gap-5">
              <Card
                id={"1"}
                key={1}
                url="https://eventos.kempetro.com.br/tour/projeto"
                //description="Este recurso tem por objetivo relacionar modelagem 3D e cronograma de montagem, presentando todo o progresso de construção planejado ao longo de um determinado período." //kempetro.com.br/?lang=en""
                title={"Tour Virtual 360°"}
                imgUrl={tour}
                index={1}
                active={active}
                handleClick={() => setActive("1")}
              />

              <Card
                id={"2"}
                key={2}
                url="https://eventos.kempetro.com.br/tour/video/"
                //description="Este recurso tem por objetivo relacionar modelagem 3D e cronograma de montagem, presentando todo o progresso de construção planejado ao longo de um determinado período."
                title={"Vídeo 360° "}
                imgUrl={galeria} //importa a imagem para assets/images e troca o nome do import la em cima
                index={2}
                active={active}
                handleClick={() => setActive("2")}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
